import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import owl1 from './Images/owl/1.png';
import owl2 from './Images/owl/2.png';
import owl3 from './Images/owl/3.png';
import owl4 from './Images/owl/4.png';
import owl5 from './Images/owl/5.png';
import owl6 from './Images/owl/6.png';
import owl7 from './Images/owl/7.png';
import './Styling/Owl.css';

const CarouselComponent = () => {
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  };

  return (
    <> 
    <h2>OUR PROUD SPONSORS</h2>
    <OwlCarousel className="owl-theme" {...options}>
      <div className="item"><h4><img src={owl1} alt='logo1'></img></h4></div>
      <div className="item"><h4><img src={owl2} alt='logo2'></img></h4></div>
      <div className="item"><h4><img src={owl3} alt='logo3'></img></h4></div>
      <div className="item"><h4><img src={owl4} alt='logo4'></img></h4></div>
      <div className="item"><h4><img src={owl5} alt='logo5'></img></h4></div>
      <div className="item"><h4><img src={owl6} alt='logo6'></img></h4></div>
      <div className="item"><h4><img src={owl7} alt='logo7'></img></h4></div>
    </OwlCarousel>
    </>
  );
};

export default CarouselComponent;
